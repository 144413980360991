import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

const modules = [
  "api_request_dashboard",
  "charge_monitoring",
  "closed_periods",
  "color_picker_field",
  "dashboards/indicator",
  "external_expense_dashboard",
  "pending_external_expense_dashboard",
  "invoices",
  "my_contribution",
  "my_deliverables",
  "my_todos",
  "organization_chart_admin",
  "production_trace",
  "programs",
  "project_definitions",
  "project_deliverables",
  "project_external_expenses",
  "project_monitoring",
  "top_bar",
  "project_phasings",
  "project_summary",
  "project_teams",
  "project_todos",
  "project_work_areas",
  "projects",
  "scroll_synchronized_table",
  "status_dashboard",
  "suppliers",
  "users",
  "users_dashboard",
  "visual_gantt",
  "legacy_work_schedule_dashboard",
  "project_changes",
  "project_change_schedule_dashboard",
  "population_change_schedule_dashboard",
  "accounting_export",
  "export_request_dashboard",
  "documents",
  "admin_documents",
  "deliverables_dashboard",
  "customers",
  "customer_contacts",
  "purchase_orders_dashboard",
  "budget_traces_dashboard",
  "tiny_mce",
  "program_planning",
  "imputation_delegations",
  "project_phasings_dashboard",
]

let elements = document.getElementsByClassName("javascript_modules_import")
elements.forEach(element => {
  let importedModules = JSON.parse(element.dataset.importedModules)
  let promises = importedModules.map(importedModule => {
    if (modules.includes(importedModule)) {
      return import(`../${importedModule}/index.js`).then(module => module.default())
    } else {
      throw new Error(`Unknown module: ${importedModule}`)
    }
  })
  Promise.all(promises)
})
